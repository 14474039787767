import React, { useMemo } from 'react'
import LazyLoad from 'react-lazyload'
import styled from 'styled-components'
import media from 'styles/media'

import { Layout } from 'views/Layout'
import { Seo } from 'components/atoms/Seo'

import { Flat } from 'types/domain'

import { graphql, navigate, PageProps } from 'gatsby'

import MapContainer from 'components/atoms/MapContainer'
import { Container } from 'components/atoms/Container'
import { LeftContentHero } from 'components/modules/LeftContentHero'
import { Breadcrumbs } from 'components/modules/Breadcrumbs'
import { InvestmentInformation } from 'components/organisms/InvestmentDescription/InvestmentInformation'
import { EstatePlants } from 'components/organisms/InvestmentDescription/EstatePlans'
import { Popup } from 'components/organisms/Popup'

// @ts-ignore
import Browser from 'browser'
// @ts-ignore
import Investment from 'investments/lawenda'

import useBreakpoint from 'hooks/useBreakpoint'
import useFlatContext from 'hooks/useFlatContext'
import useFilters from 'hooks/useFilters'

import 'swiper/css'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

const Search = React.lazy(() => import('components/organisms/SearchFlat'))
const InvestmentBenefits = React.lazy(
  () => import('components/organisms/InvestmentDescription/InvestmentBenefits')
)
const ModernSolutions = React.lazy(
  () => import('components/organisms/InvestmentDescription/ModernSolutions')
)
const WorkProgress = React.lazy(
  () => import('components/organisms/InvestmentDescription/WorkProgress')
)
const Contact = React.lazy(() => import('components/organisms/Contact'))

const BreadcrumbsContainer = styled(Container)`
  ${media.sm.max} {
    padding: 0px;
  }
`

const BrowserContainer = styled(Container)`
  max-width: none;
  height: 90vh;
  min-height: 551px;

  display: block;

  ${media.lg.max} {
    display: none;
  }
  ${media.xxl.max} {
    padding-left: 0px;
    padding-right: 0px;
  }
  ${media.sm.max} {
    padding-top: 49px;
    padding-bottom: 32px;
  }
`

const InvestmentDescription: React.FC<
  PageProps<Queries.SingleInvestmentQuery>
> = ({ data }) => {
  const { flats, addToFavourites, removeFromFavourites } = useFlatContext()
  const allFlats = flats.filter((flat) =>
    ['106C', '106E', '106D'].includes(flat.buildingName)
  )

  const {
    filteredFlats,
    availableFilters,
    currentFilters,
    setCurrentFilters,
    filterFlats,
  } = useFilters(allFlats)
  const { md } = useBreakpoint()

  const filteredFlats3D = useMemo(
    () => filterFlats(allFlats, ['area', 'rooms']),
    [allFlats, currentFilters]
  )

  const searchFlats = filteredFlats.filter(
    (flat) => flat.status === 'available' || flat.status === 'reserved'
  )

  const PAGE = data?.wpInvestmentsSingle?.investment
  const PAGE_SEO = data.wpInvestmentsSingle?.seo!

  if (!PAGE || !PAGE_SEO) {
    throw new Error("CMS data didn't load properly")
  }

  const HeroData = {
    text: PAGE.investmentHeroCommunication!,
    lable: PAGE.investmentHeroTitle!,
    image: {
      src: PAGE.investmentHeroImg?.localFile?.childImageSharp?.gatsbyImageData!,
      alt: PAGE.investmentHeroImg?.altText!,
    },
  }

  const InvestmentInformationData = {
    heading: PAGE.investmentAboutInvestmentTitle!,
    tiles: [
      {
        heading:
          PAGE.investmentAboutInvestmentTile?.[0]
            ?.investmentAboutInvestmentTileTitle!,
        description:
          PAGE.investmentAboutInvestmentTile?.[0]
            ?.investmentAboutInvestmentTileDescription!,
        image: {
          src: PAGE.investmentAboutInvestmentTile?.[0]
            ?.investmentAboutInvestmentTileImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE.investmentAboutInvestmentTile?.[0]
            ?.investmentAboutInvestmentTileImg?.altText!,
        }!,
      },
      {
        heading:
          PAGE.investmentAboutInvestmentTile?.[1]
            ?.investmentAboutInvestmentTileTitle!,
        description:
          PAGE.investmentAboutInvestmentTile?.[1]
            ?.investmentAboutInvestmentTileDescription!,
        image: {
          src: PAGE.investmentAboutInvestmentTile?.[1]
            ?.investmentAboutInvestmentTileImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE.investmentAboutInvestmentTile?.[1]
            ?.investmentAboutInvestmentTileImg?.altText!,
        }!,
      },
    ],
  }

  const EstatePlantsData = {
    heading: PAGE.investmentLocationTitle!,
    title: PAGE.investmentLocationEstatePlanTitle!,
    description: PAGE.investmentLocationEstatePlan!,
    image: {
      src: PAGE.investmentLocationImg?.localFile?.childImageSharp
        ?.gatsbyImageData!,
      alt: PAGE.investmentLocationImg?.altText!,
    }!,
  }

  const InvestmentBenefitsData = {
    heading: PAGE.investmentBenefitsTitle!,
    slides: PAGE.investmentBenefitsTiles?.map((item) => {
      return {
        positionRight: true,
        reversed: item?.investmentBenefitsTilesSite! === 'right',
        image: {
          src: item?.investmentBenefitsTilesImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: item?.investmentBenefitsTilesImg?.altText!,
        }!,
        label: item?.investmentBenefitsTilesTitle!,
        text: item?.investmentBenefitsTilesDescription!,
      }
    })!,
  }

  const ModernSolutionsData = {
    heading: PAGE.investmentBenefitsTitle!,
    cardsData: PAGE?.investmentBenefitsTiles?.map((card) => ({
      title: card?.investmentBenefitsTilesTitle!,
      text: card?.investmentBenefitsTilesDescription!,
      image: {
        src: card?.investmentBenefitsTilesImg?.localFile?.childImageSharp
          ?.gatsbyImageData!,
        alt: card?.investmentBenefitsTilesImg?.altText!,
      }!,
    }))!,
  }

  const WorkProgressData = {
    heading: PAGE.investmentWorkProgressNaglowek!,
    stages:
      PAGE?.investmentWorkProgressStages?.map((stage) => ({
        stageName: stage?.investmentWorkProgressStagesName!,
        timeline: stage?.investmentWorkProgressStagesTimeline?.map((item) => ({
          lable: item?.investmentWorkProgressStagesTimelineText!,
          isDone:
            item?.investmentWorkProgressStagesTimelineStatus === 'isDone'!,
          inProgress:
            item?.investmentWorkProgressStagesTimelineStatus === 'inProgres'!,
        }))!,
        images: stage?.investmentWorkProgressStagesGallery?.map((item) => ({
          image: {
            src: item?.investmentWorkProgressStagesGalleryImg?.localFile
              ?.childImageSharp?.gatsbyImageData!,
            alt: item?.investmentWorkProgressStagesGalleryImg?.altText!,
          }!,
          date: item?.investmentWorkProgressStagesGalleryDate!,
        }))!,
      })) || [],
  }

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />

      {PAGE?.investmentHeroPopup?.investmentHeroPopupImg?.localFile
        ?.childImageSharp?.gatsbyImageData &&
        (md ||
          PAGE?.investmentHeroPopup?.investmentHeroPopupVisibilityMobile) && (
          <Popup
            image={{
              src: PAGE?.investmentHeroPopup?.investmentHeroPopupImg?.localFile
                ?.childImageSharp?.gatsbyImageData,
              alt: PAGE?.investmentHeroPopup?.investmentHeroPopupImg?.altText!,
            }}
            link={
              PAGE?.investmentHeroPopup?.investmentHeroPopupUrl ?? undefined
            }
            delay={
              PAGE?.investmentHeroPopup?.investmentHeroPopupDelay ?? undefined
            }
          />
        )}

      <MapContainer size="big" position={['bottom', 'left']}>
        <LeftContentHero
          image={HeroData.image}
          lable={HeroData.lable}
          text={HeroData.text}
        />
      </MapContainer>

      <BreadcrumbsContainer margin={[0, 'auto']} padding={[0, 30]}>
        <Breadcrumbs crumbs={[{ name: 'Dzielnica Lawendy' }]} />
      </BreadcrumbsContainer>

      <InvestmentInformation
        heading={InvestmentInformationData.heading}
        tiles={InvestmentInformationData.tiles}
      />

      <LazyLoad once offset={!md ? 150 : 300}>
        <React.Suspense fallback={<div />}>
          <EstatePlants
            heading={EstatePlantsData.heading}
            title={EstatePlantsData.title}
            description={EstatePlantsData.description}
            image={EstatePlantsData.image}
          />
        </React.Suspense>
      </LazyLoad>

      <LazyLoad once offset={!md ? 150 : 300}>
        <React.Suspense fallback={<div />}>
          <BrowserContainer margin={[40, 0]}>
            <Browser
              investment={Investment}
              flats={filteredFlats3D}
              enterFlat={(flat: Flat) => {
                navigate(`/mieszkanie/${flat.urlID}`)
              }}
              addToFavourites={addToFavourites}
              removeFromFavourites={removeFromFavourites}
            />
          </BrowserContainer>
        </React.Suspense>
      </LazyLoad>

      <LazyLoad once offset={!md ? 150 : 300}>
        <React.Suspense fallback={<div />}>
          <Search
            flats={searchFlats}
            availableFilters={availableFilters}
            currentFilters={currentFilters}
            setCurrentFilters={setCurrentFilters}
          />
        </React.Suspense>
      </LazyLoad>

      <LazyLoad once offset={!md ? 150 : 300}>
        <React.Suspense fallback={<div />}>
          <MapContainer size="big" position={['bottom', 'left']}>
            <InvestmentBenefits
              heading={InvestmentBenefitsData.heading}
              slides={InvestmentBenefitsData.slides}
            />
          </MapContainer>
        </React.Suspense>
      </LazyLoad>

      <LazyLoad once offset={!md ? 150 : 300}>
        <React.Suspense fallback={<div />}>
          <ModernSolutions
            heading={ModernSolutionsData.heading}
            cardsData={ModernSolutionsData.cardsData}
          />
        </React.Suspense>
      </LazyLoad>

      <LazyLoad once offset={!md ? 150 : 300}>
        <React.Suspense fallback={<div />}>
          <WorkProgress
            heading={WorkProgressData.heading}
            stages={WorkProgressData.stages}
          />
        </React.Suspense>
      </LazyLoad>

      <LazyLoad once offset={!md ? 150 : 300}>
        <React.Suspense fallback={<div />}>
          <Contact
            heading={PAGE.investmentFormTitle!}
            formTitle={PAGE.investmentFormFormTitle!}
            image={{
              src: PAGE.investmentFormImg?.localFile?.childImageSharp
                ?.gatsbyImageData!,
              alt: PAGE.investmentFormImg?.altText!,
            }}
          />
        </React.Suspense>
      </LazyLoad>
    </Layout>
  )
}

export const query = graphql`
  query SingleInvestment {
    wpInvestmentsSingle(slug: { eq: "dzielnica-lawendy" }) {
      investment {
        investmentHeroTitle
        investmentHeroCommunication
        investmentHeroImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
        investmentHeroPopup {
          investmentHeroPopupImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920)
              }
            }
          }
          investmentHeroPopupUrl
          investmentHeroPopupVisibilityMobile
          investmentHeroPopupDelay
        }

        investmentAboutInvestmentTitle
        investmentAboutInvestmentTile {
          investmentAboutInvestmentTileTitle
          investmentAboutInvestmentTileDescription
          investmentAboutInvestmentTileImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 600)
              }
            }
          }
        }

        investmentLocationTitle
        investmentLocationEstatePlan
        investmentLocationEstatePlanTitle
        investmentLocationImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }

        investmentBenefitsTitle
        investmentBenefitsTiles {
          investmentBenefitsTilesTitle
          investmentBenefitsTilesDescription
          investmentBenefitsTilesSite
          investmentBenefitsTilesImg {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1100)
              }
            }
            altText
          }
        }

        investmentWorkProgressNaglowek
        investmentWorkProgressStages {
          investmentWorkProgressStagesName
          investmentWorkProgressStagesTimeline {
            investmentWorkProgressStagesTimelineStatus
            investmentWorkProgressStagesTimelineText
          }
          investmentWorkProgressStagesGallery {
            investmentWorkProgressStagesGalleryDate
            investmentWorkProgressStagesGalleryImg {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1200)
                }
              }
            }
          }
        }
        investmentInnovationsTitle
        investmentInnovationsTile {
          investmentInnovationsTileTitle
          investmentInnovationsTileDescription
          investmentInnovationsTileImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1100)
              }
            }
          }
        }
        investmentFormTitle
        investmentFormFormTitle
        investmentFormImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
      }
      seo {
        ...WpSEO
      }
    }
  }
`

export default InvestmentDescription
